import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as yup from "yup";
import React, { useState } from 'react';
import './modal-component.css';

export function ModalComponent({ isOpen, onClose }) {
    const [submitError, setSubmitError] = useState(null);

    if (!isOpen) return null;

    const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitError(null);
        try {
            console.log('Starting submission with values:', values);

            // Simplified axios call matching the working ContactForm implementation
            const response = await axios({
                method: 'post',
                url: 'https://api.stemverse.app/api/registertrial',
                data: values,
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            });
            
            console.log('Response:', response);

            if (response.status === 201 || response.status === 200) {
                alert("Registered Successfully!");
                onClose();
            } else {
                throw new Error('Unexpected response status: ' + response.status);
            }
        } catch (error) {
            console.error("Submission error:", error);
            
            let errorMessage;
            if (error.response) {
                // Server responded with error
                errorMessage = `Server error: ${error.response.status}`;
                console.error("Response data:", error.response.data);
            } else if (error.request) {
                // No response received
                errorMessage = "Network error - please check your connection";
                console.error("No response received:", error.request);
            } else {
                // Error in request setup
                errorMessage = error.message;
            }

            setSubmitError(errorMessage);
            alert(errorMessage);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close-button" onClick={onClose}>&times;</button>
                <div className="modal-body">
                    <div className="modal-image">
                        <img src="trophy.png" alt="Trophy" />
                    </div>
                    <div className="modal-form">
                        <h2>Try for free, <span className="highlight">register now</span></h2>
                        <p className="text-lg text-gray-600 mb-4">
                            Live Online Robotic/Coding Classes for Kids Aged 8-16
                        </p>
                        
                        {submitError && (
                            <div className="error-message" style={{color: 'red', marginBottom: '10px'}}>
                                {submitError}
                            </div>
                        )}

                        <Formik
                            initialValues={{
                                Parent: "",
                                Kid: "",
                                Phone: "",
                                Email: ""
                            }}
                            validationSchema={yup.object({
                                Parent: yup.string().required("Parent Name Required"),
                                Kid: yup.string().required("Kid Required"),
                                Phone: yup.string()
                                    .required("Phone Required")
                                    .matches(/^\+91\d{10}$/, "Invalid Mobile +91 and 10 digits"),
                                Email: yup.string()
                                    .required("Email Required")
                                    .email("Invalid Email")
                            })}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <Field type="text" name='Parent' placeholder="Parent's name" />
                                    <ErrorMessage name="Parent" component="div" className="error-message" />

                                    <Field type="text" name='Kid' placeholder="Kid's name" />
                                    <ErrorMessage name="Kid" component="div" className="error-message" />

                                    <Field 
                                        type="text" 
                                        name='Phone' 
                                        placeholder="Phone Number (+91XXXXXXXXXX)" 
                                    />
                                    <ErrorMessage name="Phone" component="div" className="error-message" />

                                    <Field type="email" name='Email' placeholder="Email" />
                                    <ErrorMessage name="Email" component="div" className="error-message" />

                                    <button 
                                        type="submit" 
                                        className="modal-submit-button"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Submitting...' : 'Book a free trial'}
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}