import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { useMediaQuery } from "react-responsive"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../ui/card"
import { Button } from "../../ui/button"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs"

const products = [
  {
    image: "/product.jpg?height=200&width=200",
    name: "ROBOTICS ROVER KIT",
    isSoldOut: true,
    isQuickBuy: false,
  },
  {
    image: "/product9.jpg?height=200&width=200",
    name: "HUMANOID ROBOT KIT",
    isSoldOut: true,
    isQuickBuy: false,
  },
  {
    image: "/product6.jpg?height=200&width=200",
    name: "DIY ROBOTICS ARM KIT",
    isSoldOut: true,
    isQuickBuy: false,
  },
  {
    image: "/product7.jpg?height=200&width=200",
    name: "DIY ELECTRONICS KIT",
    isSoldOut: true,
    isQuickBuy: false,
  },
  {
    image: "/product5.jpg?height=200&width=200",
    name: "GEOGRAPHY AI & IOT KIT",
    isSoldOut: true,
    isQuickBuy: false,
  },
  {
    image: "/product3.jpg?height=200&width=200",
    name: "JUNIOR ROBOTICS KIT",
    isSoldOut: true,
    isQuickBuy: false,
  },
]

const ProductCard = ({ image, name, isSoldOut, isQuickBuy }) => (
  <Card className="w-full max-w-sm">
    <CardHeader>
      <img src={image || "/placeholder.svg"} alt={name} className="w-full h-48 object-cover rounded-t-lg" />
    </CardHeader>
    <CardContent>
      <CardTitle>{name}</CardTitle>
      <CardDescription>{isSoldOut ? "Sold Out" : "In Stock"}</CardDescription>
    </CardContent>
    <CardFooter>
      <Button disabled={isSoldOut} variant={isQuickBuy ? "default" : "outline"}>
        {isQuickBuy ? "Quick Buy" : "Add to Cart"}
      </Button>
    </CardFooter>
  </Card>
)

const Product = () => {
  const navigate = useNavigate()
  const isSmall = useMediaQuery({ query: "(max-width: 640px)" })
  const [activeTab, setActiveTab] = useState("arm")

  React.useEffect(() => {
    const fromHome = sessionStorage.getItem("fromHome")
    if (fromHome) {
      navigate("/")
    } else {
      sessionStorage.setItem("fromHome", "true")
    }
    return () => {
      sessionStorage.removeItem("fromHome")
    }
  }, [navigate])

  const roboticProducts = [
    {
      id: "arm",
      title: "Robotics Arm",
      description:
        `Experience precise and smooth movement across six axes for complex tasks and versatile applications. Seamlessly integrate with STEMVerse, our AI-based website, to explore limitless possibilities in robotics programming, real-Life simulation and automation. Built with our proprietary design for optimal performance and easy connectivity. Simplified control and programming via intuitive block-based coding for learners of all ages. Perfect for students, educators, and enthusiasts to understand concepts like kinematics, object manipulation, and AI integration.`,
      image: "/robotics-rm.jpg?height=400&width=600",
    },
    {
      id: "spider",
      title: "Robotic Spider",
      description:
        `Equipped with 4 legs for realistic spider-like movement and superior stability on various terrains. Seamlessly connect with STEMVerse, our AI-based website, to program and control the robot using intuitive block-based coding. Powered by our uniquely designed microcontroller for precision control and enhanced performance. Supports obstacle avoidance, pathfinding, and other dynamic behaviors, offering hands-on experience in robotics and coding. Made from robust materials to withstand intensive usage while remaining lightweight for easy handling.`,
      image: "Spider.png?height=400&width=600",
    },
    {
      id: "car",
      title: "Robotic Car",
      description:
        `Powerful motors ensure superior performance, enabling the car to handle various terrains with ease. Utilize the Stem Engine for robust control and seamless programming. Easily integrate a variety of sensors, including ultrasonic, IR, and LDR, for tasks like obstacle avoidance, line following, and light detection.Learn and experiment with both block-based coding (via STEMVerse) and text coding for a comprehensive programming experience. Modular structure allows customization and upgradation with additional components or features. Program and operate the RC car wirelessly using Bluetooth or Wi-Fi modules for real-time control.`,
      image: "/robotic-car.jpg?height=400&width=600",
    },
    {
      id: "rover",
      title: "Robotic Rover",
      description:
        "Powerful motors ensure superior performance, enabling the rover to handle various terrains with ease. Utilize the Stem Engine for robust control and seamless programming. Easily integrate a variety of sensors, including ultrasonic, IR, and LDR, for tasks like obstacle avoidance, line following, and light detection.Learn and experiment with both block-based coding (via STEMVerse) and text coding for a comprehensive programming experience. Modular structure allows customization and upgradation with additional components or features. Program and operate the RC rover wirelessly using Bluetooth or Wi-Fi modules for real-time control.",
      image: "/Car.jpg?height=400&width=600",
    },
  ]

  return (
    <div>
      <Helmet>
        <title>STEM WORLD | Products</title>
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-center mb-8">Our Robotic Innovations</h1>

        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="w-full h-14 bg-gradient-to-r from-purple-200 to-purple-400 rounded-full p-1.5 flex items-center gap-2">
            {roboticProducts.map((product) => (
              <TabsTrigger
                key={product.id}
                value={product.id}
                className="flex-1 h-11 rounded-full text-gray-600 hover:text-gray-900 data-[state=active]:bg-white data-[state=active]:text-purple-600 data-[state=active]:shadow-md transition-all duration-200 font-medium"
              >
                {product.title}
              </TabsTrigger>
            ))}
          </TabsList>

          {/* Rest of the tabs content remains the same */}
          {roboticProducts.map((product) => (
            <TabsContent key={product.id} value={product.id}>
              <Card>
                <CardHeader>
                  <CardTitle>{product.title}</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid md:grid-cols-2 gap-6">
                    <img
                      src={product.image || "/placeholder.svg"}
                      alt={product.title}
                      className="w-full h-auto rounded-lg shadow-lg"
                    />
                    <p className="text-gray-700">{product.description}</p>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
          ))}
        </Tabs>

        <section className="mt-16">
          <h2 className="text-3xl font-bold text-center mb-8">Best-Selling Kits for Kids</h2>
          <p className="text-xl text-center mb-8">Grow, develop and play with STEM World</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {products.map((product, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <ProductCard
                  image={product.image}
                  name={product.name}
                  isSoldOut={product.isSoldOut}
                  isQuickBuy={product.isQuickBuy}
                />
              </motion.div>
            ))}
          </div>
        </section>
      </div>
    </div>
  )
}

export default Product