import React from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ContactForm = () => {
    const navigate = useNavigate();
    
    const handleSubmit = async (values, { resetForm, setSubmitting, setErrors }) => {
        try {
            console.log('Submitting values:', values);

            const response = await axios({
                method: 'post',
                url: 'https://api.stemverse.app/api/registercustomers',
                data: values,
                headers: {
                    'Content-Type': 'application/json'
                },
                // Don't use withCredentials unless you specifically need cookies
                withCredentials: false
            });
            
            console.log('Response:', response);

            if (response.status === 201) {
                alert("Customer Registered Successfully");
                resetForm();
                navigate("/contact");
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            console.error("Submission error:", error);
            
            let errorMessage = "An unexpected error occurred. Please try again.";
            
            if (error.response) {
                console.error("Error response:", error.response);
                errorMessage = error.response.data?.message || 
                             `Server error: ${error.response.status}`;
            } else if (error.request) {
                console.error("No response received:", error.request);
                errorMessage = "Could not reach the server. Please check your connection.";
            } else {
                console.error("Request setup error:", error.message);
                errorMessage = "Error preparing request. Please try again.";
            }

            alert(errorMessage);
            setErrors({ submit: errorMessage });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="right">
            <Formik
                initialValues={{
                    Name: "",
                    Email: "",
                    PhoneNumber: "",
                    Country: "India",
                    Quary: "",
                    Msg: ""
                }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors }) => (
                    <Form className="contact-form">
                        <h2>Fill the Form</h2>
                        <div className="details">
                            <div className="form-group">
                                <label className="form-label">Name <span className="text-danger"> *</span></label>
                                <Field name="Name" type="text" placeholder="Name" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="form-label">Email <span className="text-danger"> *</span></label>
                                <Field name="Email" type="email" placeholder="Email" className="form-control" />
                            </div>
                        </div>
                        <div className="details mt-3">
                            <div className="form-group">
                                <label className="form-label">Phone Number <span className="text-danger"> *</span></label>
                                <Field name="PhoneNumber" type="text" placeholder="Enter Contact No." className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="form-label">Country <span className="text-danger"> *</span></label>
                                <input className="form-control" type="text" value="India" readOnly />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Why are you contacting us? <span className="text-danger"> *</span></label>
                            <Field as="select" name="Quary" className="form-select">
                                <option value="">Select an option</option>
                                <option value="Sales Query">Sales Query</option>
                                <option value="Order Related">Order Related</option>
                                <option value="Payment">Payment</option>
                                <option value="Feedback">Feedback</option>
                                <option value="Job">Job</option>
                                <option value="Other">Other</option>
                            </Field>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Message</label>
                            <Field as="textarea" name="Msg" className="form-control" rows="6" />
                        </div>

                        {errors.submit && <div className="error-message">{errors.submit}</div>}

                        <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                            {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ContactForm;
