import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/home-page/home-page';
import StemProgram from './components/stem-program/StemProgram';
import ScienceProgram from './components/science-program/science-program';
import AtalTinkeringLab from './components/atal-tinkering-lab/atal-tinkering-lab';
import RoboticsLab from './components/robotics-lab/robotics-lab';
import KreativityLeague from './components/kreativity-league/kreativity-league';
import { Gallery } from './components/gallery/gallery';
import { Part3 } from './components/Part_3/Part_3';
import { Carousel } from './components/carousel/carousel';
import { NewHeader } from './components/new-header/new-header';
import { NewFooter } from './components/new-footer/new-footer';
import { Iframe } from './components/Iframe-container/Iframe-container';
import { FouthPage } from './components/fourth-page/FouthPage';
import Header from './components/header/header';
import ImgPop from './components/img-pop/img-pop';
import ManyImg from './components/many-img/many-img';
import { Blog } from './components/Blog/blog';
import { Contact } from './components/Contact/contact';
import { StemCom } from './components/StemCom/StemCom';
import Product from './components/Product/product';
import { SchoolProgram } from './components/school-program/school-program';
import Aiml from './components/ai-ml/ai-ml';
import Mapadd from './components/map-add/map-add';
import Printing from './components/Priting/Printing';
import Drones from './components/Drones/Drones';
import VR from './components/VR/VR';
import Electronics from './components/Electronics/Electronics';
import { Samlabs } from './components/Samlabs/samlabs';
import { LabPricing } from './components/LabPricing/labPricing';
import Education from './components/Education/education';
import OurPlatform from './components/OurPlatform/ourPlatform';
import OurPrograms from './components/OurPrograms/ourPrograms';
import OurInnovations from './components/OurInnovations/ourInnovations';
import ClassesList from './components/ClassesList/ClassesList';
import NationalCurriculum from './components/NationalCurriculum/nationalCurriculum';
import Class1 from './components/Class-1/class-1';
import Class2 from './components/Class-2/class-2';
import IXLMaths from './components/Categories/Categories';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Class3 from './components/Class-3/class-3';
import Class4 from './components/Class-4/class-4';
import Class5 from './components/Class-5/class-5';
import Class6 from './components/Class-6/class-6';
import Class7 from './components/Class-7/class-7';
import Class8 from './components/Class-8/class-8';
import Class9 from './components/Class-9/class-9';
import Class10 from './components/Class-10/class-10';
import Class11 from './components/Class-11/class-11';
import Class12 from './components/Class-12/class-12';


//All-class I to XII
import LowerKindergartenMaths from './components/KindergartenMaths/KindergartenMaths';
import UpperKindergartenMaths from './components/UpperKindergarte/UpperKindergarte';
import Classi from './components/all-class/class-i/class-i';
import Classii from './components/all-class/class-ii/class-ii';
import Classiii from './components/all-class/class-iii/class-iii';
import Classiv from './components/all-class/class-iv/class-iv';
import Classv from './components/all-class/class-v/class-v';
import Classvi from './components/all-class/class-vi/class-vi';
import Classvii from './components/all-class/class-vii/class-vii';
import Classix from './components/all-class/class-ix/class-ix';
import Classx from './components/all-class/class-x/class-x';
import Classxi from './components/all-class/class-xi/class-xi';
import Classxii from './components/all-class/class-xii/class-xii';
import Classviii from './components/all-class/class-viii/class-viii';

import Popup from './components/popup/popup';
import UserData from './components/UserData/UserData';

function App() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Set a timer to show the popup after 2 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 3000); // 2 seconds

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false); // Close the popup when the close button is clicked

  };

  return (
    <Router>
      <ScrollToTop />
      <NewHeader title="orange" />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/carousel" element={<Carousel />} />
        <Route path="/stem-program" element={<StemProgram />} />
        <Route path="/science-program" element={<ScienceProgram />} />
        <Route path="/atal-tinkering-lab" element={<AtalTinkeringLab />} />
        <Route path="/robotics-lab" element={<RoboticsLab />} />
        <Route path="/kreativity-league" element={<KreativityLeague />} />
        <Route path="/printing" element={<Printing />} />
        <Route path="/drones" element={<Drones />} />
        <Route path="/vr" element={<VR />} />
        <Route path="/electronics" element={<Electronics />} />


        <Route path="/gallery" element={<Gallery />} />
        <Route path="/part_3" element={<Part3 />} />
        <Route path="/new-header" element={<NewHeader />} />
        <Route path="/new-footer" element={<NewFooter />} />
        <Route path="/iframe-container" element={<Iframe />} />
        <Route path="/fourth-page" element={<FouthPage />} />
        <Route path="/header" element={<Header />} />
        <Route path="/img-pop" element={<ImgPop />} />
        <Route path="/many-img" element={<ManyImg />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/stemCom" element={<StemCom />} />
        <Route path="/product" element={<Product />} />
        <Route path="/school-program" element={<SchoolProgram />} />
        <Route path="/ai-ml" element={<Aiml />} />
        <Route path="/map-add" element={<Mapadd />} />
        <Route path="/samlabs" element={<Samlabs />} />
        <Route path="/labPricing" element={<LabPricing />} />
        <Route path="/education" element={<Education />} />
        <Route path="/ourPlatform" element={<OurPlatform />} />
        <Route path="/ourPrograms" element={<OurPrograms />} />
        <Route path="/ourInnovations" element={<OurInnovations />} />
        <Route path="/national-curriculum" element={<NationalCurriculum />} />
        <Route path="/class-1" element={<Class1 />} />
        <Route path="/class-2" element={<Class2 />} />
        <Route path="/class-3" element={<Class3 />} />
        <Route path="/class-4" element={<Class4 />} />
        <Route path="/class-5" element={<Class5 />} />
        <Route path="/class-6" element={<Class6 />} />
        <Route path="/class-7" element={<Class7 />} />
        <Route path="/class-8" element={<Class8 />} />
        <Route path="/class-9" element={<Class9 />} />
        <Route path="/class-10" element={<Class10 />} />
        <Route path="/class-11" element={<Class11 />} />
        <Route path="/class-12" element={<Class12 />} />

      <Route path="/UserData" element={<UserData />} />
        <Route path="/ClassesList" element={<ClassesList />} />
        <Route path="/IXLMaths" element={<IXLMaths />} />

        {/* All-class I to XII */}
        <Route path="/LowerKindergartenMaths" element={<LowerKindergartenMaths />} />
        <Route path='/UpperKindergartenMaths' element={<UpperKindergartenMaths />} />
        <Route path='/Classi' element={<Classi />} />
        <Route path='/Classii' element={<Classii />} />
        <Route path='/Classiii' element={<Classiii />} />
        <Route path='/Classiv' element={<Classiv />} />
        <Route path='Classv' element={<Classv />} />
        <Route path='/Classvi' element={<Classvi />} />
        <Route path='/Classvii' element={<Classvii />} />
        <Route path='Classviii' element={<Classviii />} />
        <Route path='Classix' element={<Classix />} />
        <Route path='Classx' element={<Classx />} />
        <Route path='Classxi' element={<Classxi />} />
        <Route path='Classxii' element={<Classxii />} />
      </Routes>
      <NewFooter />
      {/* <ChatbotToggle /> */}
    </Router>
  );
}

export default App;
