// src/App.js
import React, { useState } from 'react';
import './many.css';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const images = [
    // 'many-image1. jpg',

    'many-image6.jpg',
    'many-image7.jpg',
    'many-image9.jpg',
    'many-image10.jpg',
    'many-image11.jpg',
    'many-image12.jpg',
    'many-image13.jpg',
    'many-image14.jpg',


    // add more images as needed
];

const ManyImg = () => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const openPopup = (image) => {
        setSelectedImage(image);
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
        setSelectedImage(null);
    };

    
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is coming from the homepage
        const fromHome = sessionStorage.getItem('fromHome');

        if (fromHome) {
            // If they refreshed, redirect to the homepage
            navigate('/');
        } else {
            // Set a flag in sessionStorage to indicate the user has visited the product page
            sessionStorage.setItem('fromHome', 'true');
        }

        // Cleanup: Remove the flag when the component unmounts
        return () => {
            sessionStorage.removeItem('fromHome');
        };
    }, [navigate]);

    return (

        <div className="App">
            <Helmet>
                <title>STEM WORlD | Gallery</title>
            </Helmet>

            <div className="image-grid">
                <h1 className='gallery5'>Gallery</h1>
                {/* <img id='divider' src="https://indiastemmission.com/images/icon/divider.png" alt="" /> */}
                {/* <hr /> */}
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Image ${index}`}
                        onClick={() => openPopup(image)}
                        className="thumbnail"
                    />
                ))}
            </div>

            {popupOpen && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close-button btn btn-danger" onClick={closePopup}>
                            &times;
                        </span>
                        <img id='img1' src={selectedImage} alt="Selected" className="popup-image" />
                    </div>
                </div>
            )}

        </div>
    );
};

export default ManyImg;
