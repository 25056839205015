import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserData.css';

export default function UserData() {
    const [contactData, setContactData] = useState([]); 
    const [trialData, setTrialData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fromHome = sessionStorage.getItem('fromHome');
        if (fromHome) {
            navigate('/');
        } else {
            sessionStorage.setItem('fromHome', 'true');
        }
        return () => {
            sessionStorage.removeItem('fromHome');
        };
    }, [navigate]);

    // Fetch both contact and trial data
    useEffect(() => {
        Promise.all([
            fetch("https://api.stemverse.app/api/customers"),
            fetch("https://api.stemverse.app/api/trial")
        ])
        .then(([contactRes, trialRes]) => 
            Promise.all([contactRes.json(), trialRes.json()])
        )
        .then(([contactData, trialData]) => {
            setContactData(contactData);
            setTrialData(trialData);
            setLoading(false);
        })
        .catch(err => {
            setError(err.message);
            setLoading(false);
        });
    }, []);

    if (loading) return <h2 style={{ textAlign: 'center' }}>Loading...</h2>;
    if (error) return <h2 style={{ textAlign: 'center', color: 'red' }}>Error: {error}</h2>;

    return (
        <>
            {/* Trial Bookings Section */}
            <h1 style={{ textAlign: 'center', marginTop: '2rem' }}>Trial Bookings</h1>
            <table className='user-data-table'>
                <thead>
                    <tr>
                        <th>Serial.No</th>
                        <th>Parent's Name</th>
                        <th>Kid's Name</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {trialData.length > 0 ? (
                        trialData.map((trial, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{trial.Parent}</td>
                                <td>{trial.Kid}</td>
                                <td>{trial.Phone}</td>
                                <td>{trial.Email}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" style={{ textAlign: 'center' }}>No Trial Bookings Found</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Contact Inquiries Section */}
            <h1 style={{ textAlign: 'center', marginTop: '2rem' }}>Contact Inquiries</h1>
            <table className='user-data-table'>
                <thead>
                    <tr>
                        <th>Serial.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Query Type</th>
                        <th>Message</th>
                    </tr>
                </thead>
                <tbody>
                    {contactData.length > 0 ? (
                        contactData.map((contact, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{contact.Name}</td>
                                <td>{contact.Email}</td>
                                <td>{contact.PhoneNumber}</td>
                                <td>{contact.Quary}</td>
                                <td>{contact.Msg}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" style={{ textAlign: 'center' }}>No Contact Inquiries Found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}
