import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './chatbot.css';

const Chatbot = ({ onClose }) => {
    const [messages, setMessages] = useState([]);
    const [step, setStep] = useState(0);
    const [userData, setUserData] = useState({ Name: '', Phone: '' });

    const botAvatar = 'https://static.vecteezy.com/system/resources/previews/004/974/466/non_2x/chatbot-message-glyph-icon-talkbot-laughing-chat-bot-modern-robot-virtual-assistant-conversational-agent-silhouette-symbol-negative-space-isolated-illustration-vector.jpg';

    useEffect(() => {
        if (step === 0) {
            const timeoutId = setTimeout(() => {
                addBotMessage("Book FREE Online Demo Class!");
                addBotMessage("May I know your name please?");
                setStep(1);
            }, 500);

            return () => clearTimeout(timeoutId);
        }
    }, [step]);

    useEffect(() => {
        if (step === 2 && messages.length > 0 && messages[messages.length - 1].sender === 'user') {
            setTimeout(() => {
                addBotMessage(`Thank you ${messages[messages.length - 1].text}. Can you please share your contact number?`);
                setStep(3);
            }, 500);
        }
    }, [messages, step]);

    useEffect(() => {
        if (userData.Name && userData.Phone) {
            sendToServer(userData);
            addBotMessage("Thank you for providing your details! We will get in touch with you soon.");
            setTimeout(() => {
                onClose(); // Close the chatbot after thanking
            }, 5000);
        }
    }, [userData, onClose]);

    const addBotMessage = (text) => {
        setMessages(prev => [...prev, { sender: 'bot', text, avatar: botAvatar }]);
    };

    const addUserMessage = (text) => {
        setMessages(prev => [...prev, { sender: 'user', text }]);
    };

    const handleSend = async (values, { resetForm }) => {
        addUserMessage(values.input); // Display user message immediately
        resetForm(); // Clear the input after sending the message

        if (step === 1) {
            setUserData(prev => ({ ...prev, Name: values.input }));
            setStep(2);
        } else if (step === 3) {
            // Validate phone number
            const phoneNumber = values.input;
            const phoneRegex = /^\d{10}$/;

            if (phoneRegex.test(phoneNumber)) {
                setUserData(prev => ({ ...prev, Phone: phoneNumber }));
            } else {
                addBotMessage("Please enter a valid 10-digit phone number.");
            }
        }
    };

    const handleReset = () => {
        setMessages([]);
        setStep(0);
        setUserData({ Name: '', Phone: '' });
    };

    const sendToServer = async (data) => {
        try {
            await fetch('https://api.stemverse.app/api/ChatbotRegister', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <div className={`chatbot-container ${messages.length > 0 ? 'open' : ''}`}>
            <div className="chatbot-header">
                <span className="refresh-icon" onClick={handleReset}>⟳</span>
                <span className="close-icon" onClick={onClose}>×</span>
            </div>
            <div className="chatbot-messages">
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender}`}>
                        {message.sender === 'bot' && (
                            <img src={botAvatar} alt="Bot" className="avatar" />
                        )}
                        <div className="message-content">
                            <p>{message.text}</p>
                        </div>
                    </div>
                ))}
            </div>
            <Formik
                initialValues={{ input: '' }}
                onSubmit={handleSend}
            >
                {() => (
                    <Form className="chatbot-input">
                        {step === 3 && <div className="country-code">+91</div>}
                        <Field
                            type={step === 3 ? "tel" : "text"}
                            name="input"
                            placeholder="Type an answer"
                            pattern={step === 3 ? "\\d{10}" : undefined} // This ensures the input matches exactly 10 digits
                            title="Please enter exactly 10 digits"
                        />
                        <ErrorMessage name="input" component="div" className="error" />
                        <button type="submit">➤</button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

const ChatbotToggle = () => {
    const [showChatbot, setShowChatbot] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');

    useEffect(() => {
        if (!showChatbot) {
            const timeoutId = setTimeout(() => {
                setInfoMessage('Book FREE Online Demo Class!...');
            }, 500);

            return () => clearTimeout(timeoutId);
        } else {
            setInfoMessage('');
        }
    }, [showChatbot]);

    const toggleChatbot = () => {
        setShowChatbot(prevState => !prevState);
    };

    return (
        <div className="chatbot-toggle-container">
            {!showChatbot && infoMessage && (
                <div className="chatbot-info-message">
                    {infoMessage}
                </div>
            )}
            <button className="chatbot-toggle-button" onClick={toggleChatbot}>
                <img
                    src="https://media.istockphoto.com/id/1060696342/vector/robot-icon-chat-bot-sign-for-support-service-concept-chatbot-character-flat-style.jpg?s=612x612&w=0&k=20&c=t9PsSDLowOAhfL1v683JMtWRDdF8w5CFsICqQvEvfzY="
                    alt="Chatbot"
                />
                <div className="online-indicator"></div>
            </button>
            {showChatbot && <Chatbot onClose={toggleChatbot} />}
        </div>
    );
};

export default ChatbotToggle;
